
import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import './ChatWidget.css';


const socket = io('https://wayuptechn.com:8000', {
  transports: ['websocket', 'polling'],
  secure: true,
  rejectUnauthorized: false,
  path: '/socket.io'
});

socket.on('connect', () => {
  console.log('Connected to server');
});

socket.on('connect_error', (error) => {
  console.error('Connection error:', error.message);
});

socket.on('disconnect', (reason) => {
  console.log('Disconnected:', reason);
});

// const socket = io('http://localhost:8000'); 

const ChatWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputData, setInputData] = useState({ name: '', message: '', email: '', question: '' });
  const [activeForm, setActiveForm] = useState('chat'); // 'chat', 'call', or 'question'
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const handleSocketEvents = (eventName, successMessage, errorMessage) => {
      socket.on(eventName, (response) => {
        const newMessage = response.success
          ? successMessage(response)
          : errorMessage(response);
        setMessages(prevMessages => [...prevMessages, newMessage]);
      });
    };

    handleSocketEvents('chat message', 
      (msg) => msg,
      () => 'Failed to send message'
    );

    handleSocketEvents('call scheduled', 
      () => `Call scheduled successfully for ${inputData.name}`,
      (res) => `Failed to schedule call: ${res.error}`
    );

    handleSocketEvents('question received', 
      () => `Question received: ${inputData.question}`,
      (res) => `Failed to submit question: ${res.error}`
    );

    return () => {
      ['chat message', 'call scheduled', 'question received'].forEach(event => socket.off(event));
    };
  }, [inputData.name, inputData.question]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, message, email, question } = inputData;

    switch(activeForm) {
      case 'chat':
        if (name && message) {
          socket.emit('chat message', `${name}: ${message}`);
          setInputData(prevData => ({ ...prevData, message: '' }));
        }
        break;
      case 'call':
        if (name && email) {
          socket.emit('schedule call', { name, email });
          setActiveForm('chat');
        }
        break;
      case 'question':
        if (name && question) {
          socket.emit('ask question', { name, question });
          setActiveForm('chat');
        }
        break;
      default:
        console.log('Unknown form type');
        break;
    }
  };

  return (
    <div className="chat-widget">
      <button className="chat-toggle" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? 'Close Chat' : "Let's talk!"}
      </button>
      {isOpen && (
        <div className="chat-window">
          <div className="chat-header">
            <h3>Chat Support</h3>
          </div>
          <ul className="chat-messages">
            {messages.map((msg, index) => (
              <li key={index} className="chat-message">{msg}</li>
            ))}
            <div ref={messagesEndRef} />
          </ul>
          <form onSubmit={handleSubmit} className="chat-form">
            <input
              type="text"
              name="name"
              value={inputData.name}
              onChange={handleInputChange}
              placeholder="Your Name"
              className="chat-input"
            />
            {activeForm === 'chat' && (
              <input
                type="text"
                name="message"
                value={inputData.message}
                onChange={handleInputChange}
                placeholder="Type a message..."
                className="chat-input"
              />
            )}
            {activeForm === 'call' && (
              <input
                type="email"
                name="email"
                value={inputData.email}
                onChange={handleInputChange}
                placeholder="Your Email"
                className="chat-input"
              />
            )}
            {activeForm === 'question' && (
              <textarea
                name="question"
                value={inputData.question}
                onChange={handleInputChange}
                placeholder="Type your question..."
                className="chat-input"
              />
            )}
            <button type="submit" className="chat-send">Send</button>
          </form>
          <div className="chat-actions">
            <button onClick={() => setActiveForm('call')} className="chat-action-button">
              Schedule a Call
            </button>
            <button onClick={() => setActiveForm('question')} className="chat-action-button">
              Ask a Question
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWidget;








