import React from 'react'
import './Treasure.css'

function Treasure() {
  return (
    <div className='company-logo3'>
      <div className='scrolling-container3'>
        <div className='scrolling-text3'>
          <span className='highlight3'>EXPERIENCE</span> THE TRANSFORMING POWER OF TECHNOLOGY.
        </div> 
        <div className='scrolling-text3'>
          <span className='highlight3'>EXPERIENCE</span> THE TRANSFORMATIVE POWER OF TECHNOLOGY
        </div> 
      </div>
      </div>
      
  )
}

export default Treasure
