import React, { useState } from 'react';
import { registerUser } from '../apiServices';

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '', 
    password: ''
  })
  
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    // e.preventDefault();
    try {
      const response = await registerUser(formData)
      console.log('User registered:', response);
    } catch (error) {
      console.error('Error registering user:', error);
    }
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <input type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Name"
      />
      
      <input type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Email"
      />
      
      <input type="password"
        name="password"
        value={formData.password}
        onChange={handleChange}
        placeholder="Password" 
        />
      
      <button type="submit">Register</button>
    </form>
   
  )
};

export default Register;