import React, { useEffect } from 'react';
import Home from './Home';
import Section from './Section';
import Link from './Link';
import Product from './Product';
import Language from './Language';
import Main from './Main';
import Treasure from './Treasure';
import Services from './Services';

const HomeWrapper = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Home />
      <Section />
      <Services/>
      <Link />
      <Product />
      <Treasure />
      <Language />
      <Main />
    </>
  );
};

export default HomeWrapper;
