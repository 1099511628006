import React, { useState } from 'react';
import { submitContactForm } from '../apiServices';
import './Contact.css';
import Checkout from './Checkout';

function Contact() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    company: '',
    phoneNumber: '',
    howCanWeHelp: '',
  });

  const { fullName, email, company, phoneNumber, howCanWeHelp } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await submitContactForm(formData);
      console.log('Contact form submitted:', response);
      
      setFormData({
        fullName: '',
        email: '',
        company: '',
        phoneNumber: '',
        howCanWeHelp: '',
      });

      } catch (error) {
        console.error('Error submitting contact form:', error);
      }
  };

  return (
<>
    <div className="contact-container">
      <div className="contact-info">
        <h2>Welcome to WayUP Technology!</h2>
        <p className="first-p"> Together, let’s build something extraordinary—durable, reliable, and powered by timeless technologies that shape the future.</p>
        <p className="second-p">
          Please, fill out our contact form and we’ll be in touch with you soon. Your data is 100% secured. Thank you!
        </p>
       
      </div>
  
      <div className="form-section">
        <section className="form">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="fullName">Full Name</label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                name="fullName"
                value={fullName}
                onChange={onChange}
                autoComplete="name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={email}
                onChange={onChange}
                autoComplete="email"
              />
            </div>

            <div className="form-group">
              <label htmlFor="company">Company</label>
              <input
                type="text"
                className="form-control"
                id="company"
                name="company"
                value={company}
                onChange={onChange}
                autoComplete="organization"
              />
            </div>

            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="tel"
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                value={phoneNumber}
                onChange={onChange}
                autoComplete="tel"
              />
            </div>

            <div className="form-group">
              <label htmlFor="howCanWeHelp">How Can We Help?</label>
              <textarea
                className="form-control"
                id="howCanWeHelp"
                name="howCanWeHelp"
                value={howCanWeHelp}
                onChange={onChange}
                autoComplete="off"
              />
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-block">
                Submit
              </button>
            </div>
          </form>
        </section>
      </div>
      </div>
      <Checkout />
      </>
  );
}

export default Contact;

