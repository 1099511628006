import React from 'react'
import './Services.css'

function Services() {
  return (
    <div className="scrolling-container2">
      <div className="scrolling-content2">
        <span>Custom Software Development ★</span>
        <span>Web Development ★</span>
        <span>Mobile Application ★</span>
        <span>SEO Optimization ★</span>
        <span>Product Design ★</span>
        <span>Digital Marketing ★</span>
        <span>UX/UI Strategy ★</span>
        <span>Custom Software Development ★</span>
        <span>Web Development ★</span>
        <span>Mobile Application ★</span>
        <span>SEO Optimization ★</span>
        <span>Product Design ★</span>
        <span>Digital Marketing ★</span>
        <span>UX/UI Strategy ★</span>
      </div>
    </div>
  )
}

export default Services;
