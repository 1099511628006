import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import './Link.css';
import 'animate.css';

const gridItems = [ { 
  img: "images/vphoto8.png", 
  title: "Aerospace Research", 
  link: "/aero-research",
  description: "Our cutting-edge aerospace research software integrates seamlessly with existing systems, enhancing data analysis, simulation, and operational efficiency."
},
{ 
  img: "images/vphoto9.png", 
  title: "Banking & Finance", 
  link: "/banking-finance",
  description: "WayUP Technology excels in crafting secure and innovative software solutions for the banking and finance sector, ensuring compliance with industry standards and enhancing customer experiences."
},
{ 
  img: "images/vphoto5.png", 
  title: "Defense Research", 
  link: "/education",
  description: "WayUP Technology develops sophisticated software solutions for defense research, prioritizing security, precision, and advanced analytics to support critical missions."
},
{ 
  img: "images/vphoto4.png", 
  title: "Medical Solutions", 
  link: "/medicine-software",
  description: "Our medical software integrates advanced technologies such as AI, machine learning, and telemedicine to support accurate diagnostics, personalized treatment plans, and efficient patient monitoring."
},
{ 
  img: "images/vphoto1.png", 
  title: "Educational Solutions", 
  link: "/military-research-1",
  description: "We are dedicated to empowering educational institutions with secure, scalable, and user-friendly software systems that streamline administrative tasks and foster academic excellence."
},
{ 
  img: "images/vphoto6.png", 
  title: "Hospitality & Travel", 
  link: "/military-research-2",
  description: "We are committed to improving healthcare outcomes by delivering secure, compliant, and user-friendly medical software systems that empower healthcare professionals and optimize clinical operations."
},
  // ... (your existing gridItems array)
];

function LinkComponent() {
  const navigate = useNavigate();

  const handleOnClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
    navigate('/contact');
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate__animated', ...entry.target.dataset.animation.split(','));
        }
      });
    }, { threshold: 0.1 });

    const h1Element = document.querySelector('.global');
    if (h1Element) {
      observer.observe(h1Element);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <h1 
        className='global animate-on-scroll' 
        data-animation="animate__zoomIn,animate__delay-s"
      >
        ADVANCED SOFTWARE FOR THE MODERN INDUSTRIAL LANDSCAPE
      </h1>

      <div className="grid-link-container">
        {gridItems.map((item, index) => (
          <div className="grid-item" key={index}>
            <img src={item.img} alt={item.title} />
            <div className="text-content">
              <h3>{item.title}</h3>
              <p>{item.description}</p>
              <Link to={item.link} className="read-more">
                Read More
                <FaArrowRight style={{ marginLeft: '5px', marginBottom: '-4px', paddingLeft: '6' }} />
              </Link>
            </div>
          </div>
        ))}
      </div>

      <div className='button-container'>
        <button className='link-button' onClick={handleOnClick}>GET YOUR BUSINESS SOFTWARE</button>
      </div>
    </>
  );
}

export default LinkComponent;


