
// src/apiService.js
import axios from 'axios';


const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';


// const API_URL = process.env.NODE_ENV === 'production' 
// ? 'https://wayuptechn.com/api' 
// : 'http://localhost:8000/api';  


const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/users/register`, userData);
    return response.data;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};

export const submitContactForm = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/contact/submit`, formData);
    return response.data;
  } catch (error) {
    console.error('Error submitting contact form:', error);
    throw error;
  }
};

const loginUser = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/users/login`, userData);
    return response.data;
  } catch (error) {
    console.error('Error logging in user:', error);
    throw error;
  }
};

const updateUser = async (userData, token) => {
  try {
    const response = await axios.put(`${API_URL}/users/update`, userData, {
      headers: { 'x-auth-token': token },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

const deleteUser = async (token) => {
  try {
    const response = await axios.delete(`${API_URL}/users/delete`, {
      headers: { 'x-auth-token': token },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

export { registerUser, loginUser, updateUser, deleteUser };



