import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import Contact from './components/Contact';
import './index.css';
import Footer from './components/Footer';
import HomeWrapper from './components/HomeWrapper';
import Checkout from './components/Checkout';
import Design from './components/Design';
import Solution from './components/Solution';
import Support from './components/Support';
import Order from './components/Order';
import Navbar from './components/Navbar';
import ScrollToTop from './components/ScrollToTop';
import Register from './components/Register';
import Login from './components/Login';
import UpdateUser from './components/UpdateUser';
import DeleteUser from './components/DeleteUser';
import ChatWidget from './components/ChatWidget';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <Navbar />
          <Routes>
            <Route path="/" element={<ScrollToTop><HomeWrapper /></ScrollToTop>} />
            <Route path="/checkout" element={<ScrollToTop><Checkout /></ScrollToTop>} />
            <Route path="/design" element={<ScrollToTop><Design /></ScrollToTop>} />
            <Route path="/contact" element={<ScrollToTop><Contact /></ScrollToTop>} />
            <Route path="/solution" element={<ScrollToTop><Solution /></ScrollToTop>} />
            <Route path="/support" element={<ScrollToTop><Support /></ScrollToTop>} />
            <Route path="/order" element={<ScrollToTop><Order /></ScrollToTop>} />
            <Route path="/register" element={<ScrollToTop><Register /></ScrollToTop>} />
            <Route path="/login" element={<ScrollToTop><Login /></ScrollToTop>} />
            <Route path="/update-user" element={<ScrollToTop><UpdateUser /></ScrollToTop>} />
            <Route path="/delete-user" element={<ScrollToTop><DeleteUser /></ScrollToTop>} />
            <Route path="/chatWidget" element={<ScrollToTop><ChatWidget /></ScrollToTop>} />
          </Routes>
          <Footer />
        </div>
        <ChatWidget />
      </Router>
    </AuthProvider>
  );
}

export default App;

