
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GithubIcon from '@mui/icons-material/GitHub';
import './Footer.css';

function Footer() {
  return (
    <div className="container-box">
      <div className="footer-main">
        <div className="footer-column">
          <h3 className="footer-heading">Important</h3>
          <Link to="/digital-marketing" className="footer-link">Digital Marketing</Link>
          <Link to="/website-hosting" className="footer-link">Website Hosting</Link>
          <Link to="/website-design" className="footer-link">Website Design</Link>  
          <Link to="/domain-names" className="footer-link">Domain Names</Link>
        </div>
        <div className="footer-column">
          <h3 className="footer-heading">Support</h3>
          <Link to="/knowledge" className="footer-link">Knowledge</Link>
          <Link to="/connect-us" className="footer-link">Connect Us</Link>
          <Link to="/career" className="footer-link">Career</Link>
          <Link to="/login" className="footer-link">Login</Link>
        </div>
        <div className="footer-column">
          <h3 className="footer-heading">Company</h3>
          <Link to="/about-us" className="footer-link">About Us</Link>
          <Link to="/promo" className="footer-link">Promo</Link>
          <Link to="/blog" className="footer-link">Blog</Link>
          <Link to="/legal" className="footer-link">Legal</Link>
        </div>
        <div className="footer-column">
          <h3 className="footer-heading">WayUP Technology</h3>
          <h6>Copyright © 2024 WayUP<br/> Technology</h6>
          <h6>All Rights Reserved</h6>
          <div className="social-container">
            <a href="https://github.com/GoddeyUwamari" target="_blank"
            rel="noopener noreferrer" className="social-icon">
              <GithubIcon style={{ fontSize: '36px' }} />
            </a>
            <a href="https://twitter.com/GUwamari" target="_blank" rel="noopener noreferrer" className="social-icon">
              <TwitterIcon style={{ fontSize: '36px' }} />
            </a>
            <a href="https://www.facebook.com/emmanuel.uwamari" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FacebookIcon style={{ fontSize: '36px' }} />
            </a>
            <a href="https://www.instagram.com/emmanueluwamari/" target="_blank" rel="noopener noreferrer" className="social-icon">
              <InstagramIcon style={{ fontSize: '36px' }} />
            </a>
            <a href="https://www.linkedin.com/in/goddeyuwamri" target="_blank"
            rel="noopener noreferrer" className="social-icon">
              <LinkedInIcon style={{ fontSize: '40px' }} />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <h4>WayUP Technology</h4>
      </div>

      <div className="footer-p">
        <p>Proudly Built With ♥ by WayUP Technology</p>
      </div>
    </div>
  );
}

export default Footer;


